import { type ReactNode } from 'react';
import { tv } from 'tailwind-variants';
import {
  type DialogProps as RACDialogProps,
  type ModalOverlayProps,
  Dialog as RACDialog,
  Modal,
  composeRenderProps,
  ModalOverlay,
  Heading,
} from 'react-aria-components';
import { twMerge } from 'tailwind-merge';

import { Button, type ButtonProps } from './button';
import { CloseModalSVG } from 'components/iconsSVG/close';
import { getDomainValue } from 'utils/domain';
import { LabCloseIcon } from 'components/camperlab/icons/close';

export const overlayStyles = tv({
  base: 'fixed inset-0 isolate z-[100] flex h-[100svh] w-full duration-75 entering:ease-out entering:animate-in entering:fade-in exiting:ease-in exiting:animate-out exiting:fade-out',
  variants: {
    position: {
      center: 'items-center justify-center',
      'bottom-right': 'items-end justify-end p-2.5',
    },
    showOverlay: {
      true: 'bg-bold/45',
    },
  },
});

const modalStyles = tv({
  base: 'max-h-full max-w-full bg-clear text-left duration-200 entering:ease-out entering:animate-in entering:zoom-in-105 exiting:ease-in exiting:animate-out exiting:zoom-out-95 forced-colors:bg-[Canvas]',
  variants: {
    full: {
      true: 'max-md:h-full max-md:w-full',
      false: 'sm:w-auto',
    },
  },
});

export type DialogRootProps = ModalOverlayProps & {
  mobileFull?: boolean;
  position?: 'center' | 'bottom-right';
  showOverlay?: boolean;
};

export function DialogRoot({ className, isDismissable = true, mobileFull = false, position = 'center', showOverlay = false, ...props }: DialogRootProps) {
  return (
    <ModalOverlay
      {...props}
      isDismissable={isDismissable}
      className={composeRenderProps('', (className, renderProps) => overlayStyles({ ...renderProps, className, showOverlay, position }))}
    >
      <Modal
        {...props}
        className={composeRenderProps(className ?? 'md:max-w-screen-sm lg:max-w-screen-md', (className, renderProps) =>
          modalStyles({ ...renderProps, className, full: mobileFull }),
        )}
      />
    </ModalOverlay>
  );
}

export function Dialog(props: RACDialogProps) {
  return (
    <RACDialog
      {...props}
      className={twMerge('relative max-h-[inherit] overflow-auto bg-clear p-6 outline outline-0 lab:min-w-0 lab:p-2.5 sm:min-w-[520px]', props.className)}
    />
  );
}

export function DialogX({ onClose, absolute = true }: { onClose?: () => void; absolute?: boolean }) {
  return (
    <Button
      variant="none"
      slot="close"
      onPress={() => {
        onClose?.();
      }}
      className={`${absolute ? 'absolute right-0 top-0 z-10 m-0.5' : ''} flex items-center p-4 text-inherit lab:p-2`}
    >
      <span className="sr-only">{'Close'}</span>
      {getDomainValue({
        camper: <CloseModalSVG aria-hidden />,
        nnormal: <CloseModalSVG aria-hidden />,
        camperlab: <LabCloseIcon aria-hidden />,
      })}
    </Button>
  );
}

type DialogCloseProps = ButtonProps & {
  onClose?: () => void;
};

export function DialogClose({ onClose, ...rest }: DialogCloseProps) {
  return (
    <Button
      {...rest}
      slot="close"
      onPress={() => {
        onClose?.();
      }}
    />
  );
}

export function DialogHeader({ label, hidden = false, canClose = true }: { label: ReactNode; hidden?: boolean; canClose?: boolean }) {
  return (
    <div className={`flex flex-shrink-0 items-center ${hidden ? 'justify-end' : 'justify-between'}`}>
      <Heading slot="title" className={`mb-0 text-sm font-bold uppercase lg:text-xs ${hidden ? 'sr-only' : ''}`}>
        {label}
      </Heading>
      {canClose ?
        <DialogClose variant="none" className="flex items-center justify-center">
          <span className="sr-only">{'Close'}</span>
          {getDomainValue({ camper: <CloseModalSVG aria-hidden />, nnormal: <CloseModalSVG aria-hidden />, camperlab: <LabCloseIcon aria-hidden /> })}
        </DialogClose>
      : null}
    </div>
  );
}
