import { composeRenderProps, Button as RACButton, type ButtonProps as RACButtonProps } from 'react-aria-components';
import { tv } from 'tailwind-variants';

import { focusRing } from './component-utils';
import { ProgressCircle } from './progress-circle';

export type ButtonProps = RACButtonProps & {
  variant?: 'solid' | 'outline' | 'text' | 'none';
  color?: 'primary' | 'inverted' | 'error';
  decoration?: 'underline' | 'none';
  children: React.ReactNode;
};

// TODO: seguro que puedo hacer los colores mejor con variables estilo el checkbox
export const btnStyles = tv({
  extend: focusRing,
  base: 'group relative flex h-10 items-center justify-center px-4 text-center text-sm transition disabled:cursor-not-allowed lab:h-8 lab:justify-start lab:px-2 lab:font-bold lab:uppercase lab:lg:h-6 lab:lg:text-xs',
  variants: {
    variant: {
      solid:
        'border shadow-sm disabled:border-disabled disabled:bg-disabled disabled:text-copy lab:shadow-none lab:disabled:border-disabled lab:disabled:text-inverted',
      outline: 'border shadow-sm disabled:border-disabled disabled:text-disabled lab:shadow-none lab:disabled:border-disabled',
      text: 'bg-transparent underline-offset-2 disabled:text-disabled',
    },
    color: {
      primary: '[--color:theme(textColor.copy)]',
      inverted: '[--color:theme(textColor.inverted)]',
      error: '[--color:theme(textColor.error)]',
    },
  },
  compoundVariants: [
    // order by color
    {
      variant: 'solid',
      color: 'primary',
      className:
        'border-dark bg-neutral-inverted text-inverted hover:bg-neutral hover:text-copy pressed:bg-neutral lab:hover:border-bold lab:hover:bg-bold lab:hover:text-inverted lab:pressed:border-bold lab:pressed:bg-bold lab:pressed:text-inverted',
    },
    {
      variant: 'outline',
      color: 'primary',
      className:
        'border-dark bg-transparent text-copy hover:bg-neutral-inverted hover:!text-inverted pressed:bg-neutral-inverted pressed:!text-inverted lab:hover:border-faint lab:hover:bg-faint lab:hover:!text-copy lab:pressed:border-faint lab:pressed:bg-faint lab:pressed:!text-copy',
    },
    {
      variant: 'outline',
      color: 'inverted',
      className: 'border-inverted bg-transparent text-inverted hover:bg-neutral/20 hover:!text-inverted pressed:bg-neutral/20',
    },
    {
      variant: 'text',
      color: 'primary',
      className: 'text-copy hover:!text-copy lab:hover:underline lab:pressed:underline',
    },
    {
      variant: 'solid',
      color: 'error',
      className: 'border-error bg-error text-inverted hover:bg-neutral hover:text-error pressed:bg-neutral',
    },
    {
      variant: 'outline',
      color: 'error',
      className:
        'border-error bg-neutral text-error hover:!text-inverted pressed:bg-error lab:hover:border-transparent lab:hover:bg-error/30 lab:hover:!text-error lab:pressed:border-transparent lab:pressed:bg-error/30 lab:pressed:!text-error',
    },
    {
      variant: 'text',
      color: 'error',
      className: 'text-error hover:!text-error lab:hover:underline lab:pressed:underline',
    },
  ],
  defaultVariants: {
    variant: 'solid',
    color: 'primary',
  },
});

export const noneBtnStyles = tv({
  base: 'group',
  extend: focusRing,
});

/**
 * @example
 * ```tsx
 * <Button variant="solid" color="primary">Solid</Button>
 * <Button variant="outline" color="highlight">Outline</Button>
 * <Button variant="text" color="error">Text</Button>
 */
export function Button({ variant = 'solid', color, className, children, decoration = 'none', ...rest }: ButtonProps) {
  return (
    <RACButton
      {...rest}
      className={
        variant === 'none' ?
          composeRenderProps(className, (className, renderProps) => noneBtnStyles({ ...renderProps, className }))
        : composeRenderProps(className, (className, renderProps) => btnStyles({ ...renderProps, variant, color, className }))
      }
    >
      {({ isPending }) => (
        <>
          <span aria-label="" className="inline-flex items-center gap-1" style={isPending ? { opacity: 0 } : undefined}>
            {children}
          </span>
          {isPending ?
            <div className="absolute inset-0 flex items-center justify-center lab:justify-start lab:px-2">
              <ProgressCircle aria-label="pending" />
            </div>
          : null}
        </>
      )}
    </RACButton>
  );
}
